import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_ROOT_API;
export default class MaquinariaService {

    getMantenimientos(id) {
        return axios.get(API_URL + '/maquinaria/maquinariamantenimiento/' + id, { headers: authHeader() }) .then(response => {
            return response.data;
        }).catch(error => {
            return error.response.data;
    })
    }

    
}