<template>
    <div class="mant-bars">
        <div class="mant-bars-header">
            <div class="mant-bars-header-title">
                <slot name="title"></slot> 
            </div>
            <div class="mant-bars-header-actions" v-for="action in mantenimientos" :key="action.id" >

            <div v-if="calcDays(action.date) >= 1">
                <b>{{ action.title.replace(maquina.name,'') }} - {{ formatDate(action.date) }} </b>
                <ProgressBar   :value="calcPercent(action.date)" :class="giveMeClass(calcDays(action.date))" >
                Faltan {{calcDays(action.date)}} dias
                </ProgressBar>
            </div>


            </div>
        </div>
        <div class="mant-bars-content">
            <slot></slot>
        </div>
    </div>
</template>


<script>
import eventService from "./../services/maquinaria.service";

	export default {
        props: ['maquina'],
		data() {
			return {
                    mantenimientos: [],
                }
        },
          eventService: null,
          created() {
            this.eventService = new eventService();
             this.getMant(this.maquina.id)
          },
          mounted() {
          },
        methods: {
            formatDate(date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;

                return [day, month, year].join('-');
            },
            calcPercent(date) {
                let now = new Date();
                let date2 = new Date(date);
                let diff = date2.getTime() - now.getTime();
                let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
                
                //
                //check if positive or negative
                return 100 - diffDays
                
            },
            calcDays(date){
                let now = new Date();
                let date2 = new Date(date);
                let diff = date2.getTime() - now.getTime();
                let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
                return diffDays;
            },
            getMant(id){

      
                this.eventService.getMantenimientos(id).then(
                    (data) => {
                    this.mantenimientos = data;
                    }
                );
    
            },
     giveMeClass(number) {
         console.log('giveMeClass',number);
        let num = this.randomNumber(1, 3);
        if ( number < 10) {
            return "p-progressbar-3"
        }
        if (number < 30) {
            return "p-progressbar-2"
        }

        return "p-progressbar-1";
      
    },
    randomNumber(min,max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    
    },
        },
        mounted() {
            
        }
    }
</script>
